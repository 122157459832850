'use client'

import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Import Custom Library
import { setNeedSignUp } from "@/core/store/reducer/loginSlice";

export default function Home() {
    const dispatch = useDispatch();

    const searchParams = useSearchParams();

    const referral = searchParams.get('referral');

    useEffect(() => {
        if (referral) {
            dispatch(setNeedSignUp(true));
        }
    }, [referral]);
}
