'use client'

import dynamic from "next/dynamic";
import Image from "@/core/components/Image";
import Link from "next/link";
import { CloseCircle } from "iconsax-react";
import { useState } from "react";
import { usePathname } from "next/navigation";
import { useSelector, useDispatch } from "react-redux";

import {
  getIsNeedSignUp,
  setNeedSignIn,
  setNeedSignUp,
  setRedirectTo,
  setReferral,
} from "@/core/store/reducer/loginSlice";
import { logAction } from "@/core/helpers/analytics";

const ButtonGoogle = dynamic(() => import("./ButtonGoogle"));
const ButtonApple = dynamic(() => import("./ButtonApple"));
const LoadingSpinner = dynamic(
  () => import("@/core/components/Loader/LoadingSpinner")
);
const Modal = dynamic(() => import("@/core/components/Modal/Base"));

const bntStyles =
  "w-full flex items-center justify-center font-medium py-4 px-6 rounded-full border border-neutral-30";

const RegisterModal = () => {
  const dispatch = useDispatch();
  const isNeedSignUp = useSelector(getIsNeedSignUp);
  const [isLoading, setLoading] = useState(true);

  const path = usePathname();

  return <>
    <Modal isShow={isNeedSignUp}>
      <div
        className="rounded-lg relative w-full bg-white py-10 px-8 sm:px-16 flex flex-col overflow-auto"
        style={{
          maxHeight: "90%",
          maxWidth: 480,
          minHeight: 444,
        }}
      >
        <button type="button"
          className={"absolute right-4 top-3"}
          onClick={() => {
            dispatch(setNeedSignUp(false));
            dispatch(setRedirectTo(null));
          }}
        >
          <CloseCircle className="h-6 w-6" color="#25253F" size={24} variant="Bold" />
        </button>
        <p className="mb-2 text-xl text-center font-medium">Daftar</p>
        <p className="text-sm text-center">
          Daftar sebagai member untuk menikmati fitur lebih lengkap!
        </p>

        <div className="mt-8">
          <div className="mb-4">
            <ButtonGoogle
              setLoading={setLoading}
              onSuccessLogin={() => {
                dispatch(setNeedSignUp(false));
              }}
              onRegistered={() => {
                dispatch(setNeedSignUp(false));
              }}
            />
          </div>
          <div className="mb-4">
            <ButtonApple
              setLoading={setLoading}
              onSuccessLogin={() => {
                dispatch(setNeedSignUp(false));
              }}
              onRegistered={() => {
                dispatch(setNeedSignUp(false));
              }}
            />
          </div>
          <div>
            <Link
              href={path?.split("?")[1] ? `/register?${path?.split("?")[1]}` : "/register"}
              className={bntStyles}
              onClick={() => {
                logAction("AUTH_LS_SignUpTelp");

                if (path?.includes(process.env.NEXT_PUBLIC_IDEAL_OLX_URL)) {
                  dispatch(setReferral(process.env.NEXT_PUBLIC_IDEAL_OLX_REFERRAL))
                } else if (path?.includes(process.env.NEXT_PUBLIC_IDEAL_RUMAH123_URL)) {
                  dispatch(setReferral(process.env.NEXT_PUBLIC_IDEAL_RUMAH123_REFERRAL))
                } else {
                  dispatch(setReferral(''))
                }

                dispatch(setNeedSignUp(false));
              }}>

              <Image
                src={"/images/icons/IconPhone.svg"}
                alt="Google"
                className={"w-5"}
                height={20}
                width={20}
              />
              <span className="ml-2 text-sm">Daftar Dengan No. hp</span>

            </Link>
          </div>
        </div>

        <div className="mt-8 text-center">
          <div className="text-sm text-neutral-60">
            Sudah punya akun?
            <button
              type="button"
              className="ml-2 text-secondary-40 font-bold"
              onClick={() => {
                dispatch(setNeedSignIn(true));
                dispatch(setNeedSignUp(false));
              }}
            >
              Login di sini
            </button>
          </div>
        </div>

        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center py-12 bg-white/80">
            <LoadingSpinner height={24} width={24} />
          </div>
        )}
      </div>
    </Modal>
  </>;
};

export default RegisterModal;
