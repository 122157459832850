"use client"

// Import Custom Library
import { useLogScreen } from '@/core/helpers/analytics';
import { clearError } from '@/core/store/reducer/apiSlice';
import { useDispatch } from 'react-redux';

export default function ErrorPage() {
    useLogScreen('HP_ClientError');

    const dispatch = useDispatch();

    return (
        <main className='bg-neutral-10 flex flex-col items-center justify-center min-h-screen text-center w-screen'>
            <img src="/images/icons/IconServerError.webp" alt="500" height={256} width={256} />
            <p className="leading-6 max-w-[360px] mt-16 text-[18px] text-neutral-90 tracking-[0.25px]">Ada gangguan pada server IDEAL 500.3</p>
            <p className='max-w-[360px] mt-4 text-sm text-neutral-60 tracking-[0.25px]'>Tunggu yaaa. Tim kami sedang mencoba untuk memperbaiki ini. Kamu bisa coba lagi nanti.</p>
            <button type='button' className='font-bold mt-4 p-[6px_16px] text-secondary-40 text-sm' onClick={() => {
                dispatch(clearError());

                window.location.reload();
            }}>Coba lagi</button>
        </main>
    )
}
