'use client'

import { useEffect, useState, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import { logEvent } from "./analytics";

const useScrollTracking = (isCustom = false, element = null) => {
  const [scrollEl, setScrollEl] = useState(null);

  const bodyHeightRef = useRef(null);
  const trackingSentRef = useRef(null);
  const engagementTimeRef = useRef(null);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleScroll = (e) => {
    let position = 0;

    if (isCustom) {
      position = e?.currentTarget?.scrollTop;
    } else {
      position = e?.currentTarget?.pageYOffset;
    }
    if (
      bodyHeightRef.current && bodyHeightRef.current > 0 &&
      position >= ((bodyHeightRef.current - window.innerHeight) * 0.9) &&
      !trackingSentRef.current
    ) {
      const newTimer = window.performance.now();
      let currentEngagementTime = 0;
      if (engagementTimeRef.current) {
        currentEngagementTime = Math.round(newTimer - engagementTimeRef.current);
      }

      logEvent('scroll', {
        'engagement time': currentEngagementTime,
      });

      trackingSentRef.current = true;
    }
  };

  useEffect(() => {
    let currentEl = null;
    if (isCustom) {
      if (element) {
        currentEl = element;
      }
    } else {
      currentEl = window;
    }

    const resizeObsr = new ResizeObserver((entries) => {
      if (entries && entries?.length > 0) {
        const { blockSize: height } = entries[0].contentBoxSize[0];
        bodyHeightRef.current = height;
      }
    });

    if (currentEl) {
      engagementTimeRef.current = window.performance.now();
      // Get content height
      if (isCustom) {
        const childEl = currentEl?.firstChild;
        if (childEl) {
          resizeObsr.observe(childEl);
        }
      } else {
        resizeObsr.observe(document.body);
      }

      currentEl.addEventListener('scroll', handleScroll, { passive: true });

      setScrollEl(currentEl);
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('scroll', handleScroll);
      }
      resizeObsr.disconnect();

      bodyHeightRef.current = null;
      trackingSentRef.current = null;
      engagementTimeRef.current = null;
    };
  }, [isCustom, element, pathname, searchParams]);

  return { scrollEl };
};

export default useScrollTracking;
