'use client'

import Lottie from 'react-lottie-player';

export default function LottieAnimation({animation = null, className = "h-[204px] relative w-full"}) {
    return (
        <Lottie
            loop
            animationData={animation}
            play
            className={className}
        />
    )
}
