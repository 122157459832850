import(/* webpackMode: "eager" */ "/app/app/(root)/(default)/(with-nav-menu)/home/home-page.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/home/bank-partners/ListBank.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/home/comparison/ComparisonClient.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/home/developer-partners/DeveloperPartnersClient.jsx");
;
import(/* webpackMode: "eager" */ "/app/components/home/LottieAnimation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/home/media/MediaClient.jsx");
;
import(/* webpackMode: "eager" */ "/app/components/home/PropertyCategory.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/img-banner.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/icons/IconCheckDark.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/icons/IconCheckPrimary.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/icons/IconReview.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/icons/IconReviewHalfStar.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/icons/IconReviewStar.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/icons/IconSummary.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo-aftech-color.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo-cbqa_iso_27001-text.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo-kominfo-color.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo-ojk.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo/IDEAL.svg");
