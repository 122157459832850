import { configureStore } from '@reduxjs/toolkit';

import reducer from "./reducer/index";

const loadState = () => {
    try {
        if (typeof localStorage !== 'undefined') {
            const serializedState = localStorage.getItem(process?.env?.NEXT_PUBLIC_STORAGE_NAME);
            return serializedState ? JSON.parse(serializedState) : undefined;
        }
    } catch (err) {
        console.error('Failed to load state:', err);
        return undefined;
    }
};

const saveState = (state) => {
    try {
        if (typeof localStorage !== 'undefined') {
            const serializedState = JSON.stringify(state);
            localStorage.setItem(process?.env?.NEXT_PUBLIC_STORAGE_NAME, serializedState);
        }
    } catch (err) {
        console.error('Failed to save state:', err);
    }
};

// Creating the store again
const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    preloadedState: loadState()
});

store.subscribe(() => {
    saveState(store.getState());
});

export { store }
